<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import EnterpriseSettingTable from '@/components/enterprise/shared/EnterpriseSettingTable';

  export default {
    components: {
      'page-data-section': PageDataSection,
      'ent-setting-table': EnterpriseSettingTable
    },
    data() {
      return {
        departmentId: this.$route.query.department || '',
        progressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsAdminOfEnterprise', 'userIsFinanceManager']),
      ...mapState('EnterpriseStore', {
        departments: (state) => state.departments || [],
        allDepartments: (state) => state.allDepartments || []
      }),
      departmentFilterText() { return this.$gettext('Filter by department'); },
      hasDepartments() { return this.allDepartments.length; },
      canChange() { return this.userIsAdminOfEnterprise || this.userIsFinanceManager; },
      departmentsFilterList() {
        const list = this.allDepartments.map((item) => {
          return {id: item.id, name: item.name};
        });
        list.unshift({id: '', name: this.$gettext('All')});
        return list;
      }
    },
    watch: {
      $route() {
        this.filterData();
      },
      departmentId() {
        if (this.departmentId) {
          this.$router.push(this.$makeRoute({name: this.$route.name, query: {department: this.departmentId, page: 1}}));
        } else {
          this.$router.push(this.$makeRoute({name: this.$route.name, query: {page: 1}}));
        }
      }
    },
    methods: {
      ...mapMutations('EnterpriseStore', [
        'removeDepartments',
        'removeAddresses'
      ]),
      changeDepartment(value) { this.departmentId = value; }
    },
    beforeMount() {
      if (this.departmentId) {
        this.filterData();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.removeDepartments();
      this.removeAddresses();
      next();
    }
  };
</script>

<style>
  .ent-settings .page-data-section__heading {
    padding: 20px 20px 0;
  }

  .ent-settings .page-data-section__title {
    display: flex;
  }

  .ent-settings .title-note {
    margin-left: auto;
    color: #f93;
    font-weight: normal;
    font-size: 12px;
  }

  @media (max-width: 1024px) {
    .ent-settings .page-data-section__heading {
      padding: 15px 15px 0;
    }

    .ent-settings .title-note {
      display: none;
    }
  }
</style>

<style scoped src="@assets/css/enterprise_settings_page.css"></style>
