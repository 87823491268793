// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/trash_ico_black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.department[data-v-298d30b7] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 20px;\n  border-top: 1px solid #d3d5de;\n  font-size: 12px;\n}\n.department__information[data-v-298d30b7] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  width: 100%;\n}\n.department__references[data-v-298d30b7] {\n  width: 100%;\n  max-width: 70%;\n  padding-right: 10px;\n}\n.department-info__control-btn[data-v-298d30b7] {\n  display: block;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n  color: #ff5b24;\n  color: var(--color-secondary);\n  font-weight: bold;\n  text-decoration: underline;\n  cursor: pointer;\n}\n.department-info__control-btn[data-v-298d30b7]:focus {\n  outline: var(--default-button-focus-outline);\n}\n.btn-min-content[data-v-298d30b7] {\n  width: -webkit-min-content;\n  width: -moz-min-content;\n  width: min-content;\n  padding-top: 5px;\n}\n.department__remove-btn[data-v-298d30b7] {\n  display: block;\n  width: 24px;\n  height: 24px;\n  margin-top: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: 15px auto;\n  background-repeat: no-repeat;\n}\n.department__remove-btn[data-v-298d30b7]:focus {\n  outline: var(--default-button-focus-outline);\n}\n@media (max-width: 1024px) {\n.department[data-v-298d30b7] {\n    padding: 15px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
