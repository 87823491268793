import { render, staticRenderFns } from "./b_Departments.vue?vue&type=template&id=38d2f5cf&scoped=true&"
import script from "./b_Departments.vue?vue&type=script&lang=js&"
export * from "./b_Departments.vue?vue&type=script&lang=js&"
import style0 from "./b_Departments.vue?vue&type=style&index=0&id=38d2f5cf&prod&lang=css&"
import style1 from "@assets/css/enterprise_settings_page.css?vue&type=style&index=1&id=38d2f5cf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d2f5cf",
  null
  
)

export default component.exports