(<template>
  <article :class="{'in-progress': progressActive}"
           class="department is-progress-bar">
    <div class="department__information department-info">
      <p class="department-info__row"
         v-html="name"></p>
      <p class="department-info__row"
         v-html="email"></p>
      <p class="department-info__row"
         v-html="phone"></p>
      <p class="department-info__row"
         v-html="employee"></p>
      <p class="department-info__row">
        <strong>{{ $gettext('Address(es):') }}</strong>
        <span v-html="getAddress"></span>
      </p>
      <button v-if="userIsAdminOfEnterprise"
              class="department-info__control-btn btn-min-content"
              @click="editItem">{{ $gettext('Edit') }}</button>
    </div>
    <div class="department__references">
      <p v-if="buyerRef"
         class="department-info__row"
         v-html="buyerRefContent"></p>
      <button v-else-if="userIsAdminOfEnterprise"
              class="department-info__control-btn"
              @click="editItem">{{ btnAddBuyerText }}</button>
      <p v-if="orderRef"
         class="department-info__row"
         v-html="orderRefContent"></p>
      <button v-else-if="userIsAdminOfEnterprise"
              class="department-info__control-btn"
              @click="editItem">{{ btnAddOrderText }}</button>
      <p class="department-info__row"
         v-html="paymentMethod"></p>
    </div>
    <button v-if="userIsAdminOfEnterprise"
            :title="removeText"
            class="department__remove-btn"
            @click="deleteItem"></button>
  </article>
</template>)

<script>
  import helpers from '@/helpers';
  import {mapGetters} from 'vuex';

  export default {
    props: {
      department: {
        type: Object,
        default() {
          return {};
        }
      },
    },
    data() {
      return {
        progressActive: false,
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsAdminOfEnterprise']),
      name() {
        return `${this.$gettext('<strong>Department name:</strong>')} ${this.department.name || '-'}`;
      },
      email() {
        return `${this.$gettext('<strong>Email:</strong>')} ${this.department.email || '-'}`;
      },
      phone() {
        return `${this.$gettext('<strong>Phone number:</strong>')} ${this.department.phoneCode + this.department.phoneNumber || '-'}`;
      },
      employee() {
        return `${this.$gettext('<strong>Employee:</strong>')} ${this.department.memberCount || '-'}`;
      },
      buyerRef() { return this.department.defaultBookingReference; },
      orderRef() { return this.department.defaultPaymentBookingReference; },
      buyerRefContent() {
        const buyerRefName = this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Buyer reference');
        return `<strong>${buyerRefName}:</strong> ${this.buyerRef}`;
      },
      orderRefContent() {
        const orderRefName = this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Order reference');
        return `<strong>${orderRefName}:</strong> ${this.orderRef}`;
      },
      paymentMethod() {
        return `${this.$gettext('<strong>Payment method:</strong>')} ${this.department.paymentMethodName}`;
      },
      getAddress() {
        let addresses;
        if (this.department.addresses?.length > 0) {
          const fulladdress = this.department.addresses.map((address) => helpers.getFullAddress(address));
          addresses = fulladdress.join('<br/>');
        } else {
          addresses = '-';
        }
        return addresses;
      },
      removeText() { return this.$gettext('Remove'); },
      btnAddBuyerText() { return this.$gettext('Add buyer reference'); },
      btnAddOrderText() { return this.$gettext('Add order reference'); },
    },
    methods: {
      editItem() {
        this.$emit('edititem', this.department);
      },
      deleteItem() {
        this.$emit('deleteitem', this.department.id);
      },
    }
  };
</script>

<style scoped>
  .department {
    position: relative;
    display: flex;
    padding: 20px;
    border-top: 1px solid #d3d5de;
    font-size: 12px;
  }

  .department__information {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .department__references {
    width: 100%;
    max-width: 70%;
    padding-right: 10px;
  }

  .department-info__control-btn {
    display: block;
    width: fit-content;
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .department-info__control-btn:focus {
    outline: var(--default-button-focus-outline);
  }

  .btn-min-content {
    width: min-content;
    padding-top: 5px;
  }

  .department__remove-btn {
    display: block;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    background-image: url(~@assets/imgs/undefined_imgs/trash_ico_black.svg);
    background-position: 50% 50%;
    background-size: 15px auto;
    background-repeat: no-repeat;
  }

  .department__remove-btn:focus {
    outline: var(--default-button-focus-outline);
  }

  @media (max-width: 1024px) {
    .department {
      padding: 15px;
    }
  }
</style>
